<template>
  <section class="eui-t-parapher-show" v-if="parapher">
    <documentPreviewDialog
      :document="documentDialog"
      @closeDialog="documentDialog = undefined"
      v-if="documentDialog"/>
    <EuiGrid gutters>
      <EuiGridRow>
        <EuiGridColumn>
          <!-- **** TOOLBAR **** -->
          <div class="m-0 flex items-center">
            <EuiButton iconOnly size="large" variant="text" @click="$emit('goBack')">
              <EuiIcon name="chevron_left" color="primary"/>
            </EuiButton>
            <EuiHeading :level="1" bold>{{ $t('document.add.table.name') }} {{ parapher.name }}</EuiHeading>
            <div class="flex flex-1 justify-end">
              <EuiButton color="primary" variant="raised" @click="getArchive([parapher.signBookId, parapher.name])">
                {{ $t('button.download') }}
              </EuiButton>
            </div>
          </div>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$i18n.t('signworkflows.subtitle.1')">
            <EuiGrid>
              <EuiGridRow>
                <EuiGridColumn>
                  <EuiHeading :level="3" bold class="mb-3">{{ $t('signworkflows.create.step1.globalParameters.reminder.title')}}</EuiHeading>
                  <div class="flex items-center" v-if="parapher.alertConfiguration">
                    <EuiTextField>
                      <EuiLabel>{{ $t('signworkflows.create.step1.globalParameters.reminder.nb.label') }}</EuiLabel>
                      <EuiInput type="number" min="1" max="999" v-model="parapher.alertConfiguration.frequency" readonly/>
                    </EuiTextField>
                    <EuiTextField>
                      <EuiLabel>{{ $t('signworkflows.create.step1.globalParameters.reminder.interval.label') }}
                      </EuiLabel>
                      <EuiInput type="number" min="1" max="999" v-model="parapher.alertConfiguration.intervall" readonly/>
                    </EuiTextField>
                  </div>
                </EuiGridColumn>
                <EuiGridColumn>
                  <EuiHeading :level="3" bold class="mb-3">{{ $t('signworkflows.create.step1.globalParameters.closingParameters.title')}}</EuiHeading>
                  <div class="flex items-center">
                    <div class="flex flex-col mr-4">
                      <div class="text-blue-grey font-bold">{{ $t('signworkflows.create.step1.globalParameters.endingDate.label') }}:</div>
                      <div class="text-blue-grey font-bold">{{ $t('signworkflows.create.step1.globalParameters.notificationEmail.label') }}:</div>
                    </div>
                    <div class="flex flex-col">
                      <div v-if="parapher.endingDate">{{ $d(new Date(parapher.endingDate), 'short') }}</div>
                      <div>{{ parapher.notificationEmail }}</div>
                    </div>
                  </div>
                </EuiGridColumn>
              </EuiGridRow>
            </EuiGrid>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$t('signworkflows.documents')">
            <EuiTable v-if="parapher.documents && parapher.documents.length > 0">
              <EuiTableHeader>
                <EuiTableRow>
                  <!--name -->
                  <EuiTableHead/>
                  <EuiTableHead>{{ $t('document.add.table.name') }}</EuiTableHead>
                  <EuiTableHead>Type</EuiTableHead>
                </EuiTableRow>
              </EuiTableHeader>
              <EuiTableBody>
                <!-- table row -->
                <EuiTableRow v-for="(doc, index) in parapher.documents" :key="doc.id" @click.native="showDoc(doc)">
                  <EuiTableCell dataType="input">
                    <EuiIcon :name="doc.extension === 'pdf' ? 'pdf' : 'file'" size="medium"/>
                  </EuiTableCell>
                  <EuiTableCell>{{ doc.name }}</EuiTableCell>
                  <EuiTableCell>
                    {{ parapher.documents[index].typeDescription }}
                  </EuiTableCell>
                </EuiTableRow>
              </EuiTableBody>
            </EuiTable>
          </EuiBlock>
        </EuiGridColumn>
        <EuiGridColumn>
          <EuiBlock :title="$t('components.templates.parapher.edit.organizations.title')">
            <EuiTable v-if="parapher.recipientOrganizations && parapher.recipientOrganizations.length > 0">
              <EuiTableHeader>
                <EuiTableRow>
                  <!--name -->
                  <EuiTableHead>{{ $t('signworkflows.create.form.organizations.table.name') }}</EuiTableHead>
                </EuiTableRow>
              </EuiTableHeader>
              <EuiTableBody>
                <!-- table row -->
                <EuiTableRow v-for="item in parapher.recipientOrganizations" :key="item.id">
                  <EuiTableCell :main=true>{{ item.name }}</EuiTableCell>
                </EuiTableRow>
              </EuiTableBody>
            </EuiTable>
            <template v-else>
              <!-- add organizations -->
              <p class="mb-4">
                {{ $t('signworkflows.create.form.organizations.none') }}
              </p>
            </template>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <!-- signatories -->
          <EuiBlock :title="$t('components.templates.parapher.edit.signatories')">
            <div class="eui-u-flex eui-u-flex-wrap eui-u-items-center">
              <div>
                <EuiHeading :level="4" bold class="eui-u-mb-3">
                  {{ $t('signworkflows.create.form.signatory.signatureOrdering') }}
                </EuiHeading>
                <div  class="eui-u-mr-5 eui-u-flex eui-u-flex-wrap eui-u-items-center">
                  <EuiRadio disabled name="signatureOrdering" value=""
                            :selected="parapher.allowSimultaneousSteps === false
                            && parapher.allowFirstThenSimultaneousSteps === false">
                    {{ $t('signworkflows.create.form.signatory.sequentially') }}
                  </EuiRadio>
                  <EuiTooltip :content="$t('signworkflows.create.form.signatory.sequentially.tooltip')" placement="top">
                    <template slot="anchor">
                      <EuiIcon class="eui-u-ml-1 eui-u-mr-5" name="infosperso" color="primary"/>
                    </template>
                  </EuiTooltip>
                  <EuiRadio disabled name="signatureOrdering" value=""
                            :selected="parapher.allowSimultaneousSteps === true
                            && parapher.allowFirstThenSimultaneousSteps === false">
                    {{ $t('signworkflows.create.form.signatory.simultaneously') }}
                  </EuiRadio>
                  <EuiTooltip :content="$t('signworkflows.create.form.signatory.simultaneously.tooltip')" placement="top">
                    <template slot="anchor">
                      <EuiIcon class="eui-u-ml-1 eui-u-mr-5" name="infosperso" color="primary"/>
                    </template>
                  </EuiTooltip>
                  <EuiRadio disabled name="signatureOrdering" value=""
                            :selected="parapher.allowSimultaneousSteps === false
                            && parapher.allowFirstThenSimultaneousSteps === true">
                    {{ $t('signworkflows.create.form.signatory.hybrid') }}
                  </EuiRadio>
                  <EuiTooltip :content="$t('signworkflows.create.form.signatory.hybrid.tooltip')" placement="top">
                    <template slot="anchor">
                      <EuiIcon class="eui-u-ml-1" name="infosperso" color="primary"/>
                    </template>
                  </EuiTooltip>
                </div>
              </div>
              <div>
                <EuiHeading :level="4" bold class="eui-u-mb-3">
                  {{ $t('signworkflows.create.form.signatory.refuse') }}
                </EuiHeading>
                <EuiCheckbox v-model="parapher.allowDecline" disabled>
                  {{ $t('signworkflows.create.form.signatory.canRefuse') }}
                </EuiCheckbox>
              </div>
            </div>
            <EuiHeading :level="4" bold class="eui-u-mt-3">
              {{ $t('signworkflows.create.form.signatory.signatoriesOrder')}}
            </EuiHeading>
            <div class="mt-5">
                <EuiExpansionPanel v-for='(element, index) in parapher.steps'
                                   :key='`sign_${index}`'
                                   class="bg-white"
                                   :class="{'bt-px': index === 0 }">
                  <template v-slot:header>
                    <div class="flex-1">
                      <div class="text-blue-grey font-bold mb-2 leading-none">{{ $t('signworkflows.create.step1.signatories.fullname.label') }}</div>
                      <div class="text-xs">{{element.signerName}}</div>
                    </div>
                    <div class="flex-1">
                      <div class="text-blue-grey font-bold mb-2 leading-none">{{ $t('signworkflows.create.step1.signatories.type.label') }}</div>
                      <div class="text-xs">{{ $t('signworkflows.create.step1.signatories.type.' + element.type) }}</div>
                    </div>
                    <div class="flex-1" :style="{ visibility: element.type === 'externalSignatory' && element.organizations && element.organizations.length > 0 ? 'visible' : 'hidden' }">
                      <div class="text-blue-grey font-bold mb-2 leading-none">{{ $t('signworkflows.create.step1.signatories.organization.label') }}</div>
                      <div class="text-xs" v-if="element.organizations && element.organizations.length > 0">{{ element.organizations.map(value => value.name).join(', ') }}</div>
                    </div>
                    <div class="flex-1" @click.stop>
                        <div class="text-blue-grey font-bold mb-2 leading-none">Signature</div>
                        <div class="text-xs" v-if="element.executionDate">{{ $d(new Date(element.executionDate.replace(/-/g, '/')), 'short') }}</div>
                    </div>
                  </template>
                  <div>
                    <EuiHeading :level="4" bold class="mb-3">{{ $t('common.advancedParameters')}}</EuiHeading>
                    <EuiCheckbox disabled v-model="element.signerOnlyValidate">
                      <span v-html="$t('signworkflows.create.step1.signatories.signerOnlyValidate.label')"></span>
                    </EuiCheckbox>

                    <EuiTextField adaptSize class="mt-2" noResize>
                      <EuiLabel>{{ $t('signworkflows.create.step1.signatories.comment.label') }}</EuiLabel>
                      <!--<EuiTextarea v-if="element.executionDate === null" fullWidth :rows="2" v-model="element.comment" autocomplete="personnal-comment"/>-->
                      <p v-if="element.comment" class="ml-3">{{ element.comment }}</p>
                      <p v-else class="ml-3">{{ $t('signworkflows.edit.globalMessage.none') }}</p>
                    </EuiTextField>
                  </div>
                </EuiExpansionPanel>
            </div>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$t('signworkflows.create.step1.thirdPartyEmails.title')">
            <EuiList v-if="parapher.thirdParties && parapher.thirdParties.length > 0">
              <EuiListItem v-for="item in parapher.thirdParties" :key="item.thirdPartyEmail">
                <template v-slot:graphic>
                  <EuiIcon name="person"/>
                </template>
                {{ item.thirdPartyEmail }}
              </EuiListItem>
            </EuiList>
            <p v-else>{{ $t('signworkflows.edit.thirdPartiesEmails.none')}}</p>
          </EuiBlock>
        </EuiGridColumn>
        <EuiGridColumn>
          <EuiBlock :title="$t('signworkflows.create.step1.comment.title')">
            <template v-if="parapher.globalMessage">
              <EuiLabel>{{ $t('signworkflows.create.step1.comment.label') }}</EuiLabel>
              <p>{{ parapher.globalMessage }}</p>
            </template>
            <p v-else>{{ $t('signworkflows.edit.globalMessage.none') }}</p>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
  </section>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  props: {
    parapher: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      documentDialog: undefined,
    };
  },
  components: {
    documentPreviewDialog: () => import(/* webpackPrefetch: true */ '../../../pages/paraphers/dialogs/DocumentPreviewDialog'),
  },
  methods: {
    ...mapActions({
      getDocContent: 'ModuleEdocSign/parapheurs/getDocContent',
      getArchive: 'ModuleEdocSign/parapheurs/getArchive',
    }),
    base64ToFile(doc) {
      const binary = window.atob(doc.fileContent);
      const converter = new ArrayBuffer(binary.length);
      const array = new Uint8Array(converter);
      for (let i = 0; i < binary.length; i += 1) {
        array[i] = binary.charCodeAt(i);
      }
      return new File([converter], doc.name, { type: 'application/pdf' });
    },
    showDoc(doc) {
      this.getDocContent({ id: doc.signBookDocumentId })
        .then((response) => {
          doc.fileContent = response.fileContent;
          this.documentDialog = { id: doc.signBookDocumentId, content: this.base64ToFile(doc), fileContent: doc.fileContent };
        });
    },

  },
};
</script>
