<template>
  <EuiContainer>
    <TemplateParapherShow :parapher="parapher" @goBack="$router.go(-1);"/>
  </EuiContainer>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TemplateParapherShow from '../../components/templates/parapher/show';

export default {
  name: 'guestDetailsSignworkflow',
  components: {
    TemplateParapherShow,
  },
  props: {
    signBookId: {
      type: [String, Number],
      required: false,
    },
  },
  computed: {
    ...mapState({
      parapher: (state) => state.ModuleEdocSign.parapheurs.signBook,
    }),
  },
  methods: {
    ...mapActions({
      getSignBookById: 'ModuleEdocSign/parapheurs/getSignBookById',
    }),
  },
  watch: {
    signBookId(value) {
      this.getSignBookById(value);
    },
  },
  mounted() {
    this.getSignBookById(this.signBookId);
  },
};
</script>
